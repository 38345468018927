<template>
  <div>
    <TopNavVue/>
    
    <el-row class="cloudDesktop">
      <el-col :span="22" :offset="1">
        <!-- 页面标题 -->
        <h6 class="title">磐石云桌面</h6>
      </el-col>

      <el-col :span="22" :offset="1" class="cloa">
        <p>云计算技术的发展正在改变着社会生产和人们的生活，面向云桌面及应用发布解决方案纷芸而出，满怀科技创新历史使命的众多高科技企业通过自己的努力为这个时代作出了卓越贡献，云上应用正在改变传统，按需交付即将主导未来。</p>
        <p>磐石云桌面通过统一管理平台 (控制引擎) 将虚拟的桌面组件 (包括应用软件、操作系统和用户数据等) 部署在云数据中心，进行统一管理、集中控制和按需发布。支持向PC、平板设备、笔记本电脑和云终端交付云桌面或应用，实现媲美传统PC及图形工作站的体验效果，既可以发布整个桌面系统，又支持单独发布应用。支持多种接入平台，有效降低基础设施投入和运维成本，提高应用安全和数据安全，重新定义云管模型，重塑工作方式，为用户打造更加灵活的应用交付模式和办公空间。</p>
        <!-- <img src="@/assets/panshProduct/cloudDesktop/a1.jpg" /> -->
        <img src="@/assets/panshProduct/cloudDesktop/yzmm.png" />
      </el-col>

      <el-col :span="22" :offset="1">
        <h6 class="title">产品架构</h6>
        <div class="clob">
          <p>磐石云桌面支持常见的终端设备，兼容多类型操作系统，集成磐石云桌面客户端，实现访问虚拟桌面或虚拟应用。同时支持内部网络（局域网、专线）、广域网（Internet、4G/5G、Wifi）方式接入，集成磐石盾“量子动态密钥”安全措施，保障接入安全。管理层包括用于交付桌面和应用的基础交付模块和高级模块。支持交付虚拟桌面和虚拟应用，支持将专业软件使用许可通过应用发布等组件以“许可服务”方式在云数据中心进行托管，并支持进行统一管理、集中控制和按需发布。主机层用于构建物理和虚拟桌面资源池，支持基于网络存储的传统虚拟化架构（Citrix Hypervisor、VMware vSphere），也支持超融合架构（VMware vSAN、SmartX、Nutanix、QingCloud等），同时支持基于云基础设施（阿里云、华为云平台）进行交付。</p>
          <img src="@/assets/panshProduct/cloudDesktop/pic1.png"/>
        </div>
      </el-col>
      
      <el-col :span="22" :offset="1">
        <h6 class="title">产品功能</h6>
        <div class="clob">
          <div v-for="(item,key) in proFunction" :key="key" class="cloba">
            <img :src="item.imgSrc"/>
            <p>{{item.title}}</p>
            <p>{{item.desc}}</p>
          </div>
          <div class="clearfix"></div>
        </div>
      </el-col>

      <el-col :span="22" :offset="1">
        <h6 class="title">产品特色</h6>
        <div class="cloc">
          <img src="@/assets/mobile/moPanshProduct/c5.png" alt="">
          <div class="cloca">
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>安全数据架构模型，数据不落地</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>磐石盾身份验证、动态量子、安全二维码</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>多模式交付，按需定制</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>虚拟应用资源交付，资源占用低</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>媲美PC的用户体验</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>完美的软件兼容性</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>广泛的外设支持</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>成熟的vGPU支持</p>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="cloc">
          <img src="@/assets/mobile/moPanshProduct/c6.png" alt="">
          <div class="cloca">
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>统一控制引擎平台，管理、交付、监控于一体</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>定制化云端管理功能</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>移动管理</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>自动运维</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>任意时间、任意地点，使用任意企业应用；</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>支持多种场景，适应多种环境</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>全面适配麒麟、统信等国产操作系统；</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>深度融合nutanix、vmware、SmartX、青云等主流底层虚拟资源平台</p>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      
      <el-col :span="22" :offset="1" style="margin-top: 20px;">
        <h6 class="title">应用场景</h6>
        <div class="clob">
          <img src="@/assets/panshProduct/cloudDesktop/yycj.png" alt="">
        </div>
      </el-col>
    </el-row>

    <footerMobileVue/>


  </div>
</template>

<script>
import TopNavVue from '../../../components/topNav.vue'
import footerMobileVue from '../../../components/footerMobile.vue';
export default {
  name: "cloudDesktopMobile",
  components:{footerMobileVue,TopNavVue},
  data() {
    return {
      proFunction:[
        {title:'安全接入',desc:'通过多元安全认证、磐石盾、量子密钥等多种身份认证方式，保证用户登录安全，将用户数据集中存储，统一管理，保障数据永不丢失。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l1.png'),},
        {title:'应用商店',desc:'统一用户资源看板，对托管资源站点的用户进行身份验证并管理已授权的桌面和应用程序，确保在多个设备之间向用户提供一致的体验。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l9.png'),},
        {title:'控制引擎',desc:'中枢管理组件，由用于管理虚拟化资源、应用程序和桌面的服务组成，负责资源交付、用户授权、许可管理、资源监控及日志报表等。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l3.png'),},
        {title:'多桌面形态',desc:'支持链接克隆、完整克隆以及流桌面置备多形态桌面发布，包括Windows、Linux以及麒麟、统信等国产操作系统。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l5.png'),},
        {title:'监控运维',desc:'对物理及虚拟资源的集中管理、统一监控；全状态监控系统，从底层资源利用、会话接入性能等多个层面监控云桌面平台的使用性能情况。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l4.png'),},
        {title:'全面兼容',desc:'全面兼容主流外接设备，通过策略灵活控制外设的重定向实现即插即用，包括打印机、高拍仪、Ukey、身份阅读器、指纹仪等。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l6.png'),},
        {title:'多资源支持',desc:'传统虚拟化架构：Xen、Vmware；超融合架构：SmartX、Nutanix；公有云架构：青云、华为云',imgSrc:require('@/assets/panshProduct/cloudDesktop/l10.png'),},
        {title:'信创桌面',desc:'围绕信创规范，建构磐石生态，全面适配麒麟、统信等主流国产操作系统，通过适配动作持续优化产品形态和兼容模式。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l11.png'),},
        {title:'vGPU控制',desc:'成熟的vGPU支持，通过图像策略优化以及编码技术，实现图形处理以及研发设计等场景的完美锲合。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l12.png'),},
        {title:'窄宽带',desc:'通过ICA协议，适应多种网络条件，实现优质的低带宽条件下用户体验。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l13.png'),},
        {title:'安全措施',desc:'提供统一服务地址和接入端口，与服务发布平台网络隔离，支持用户灵活访问，有效兼顾生产效率和安全举措。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l14.png'),},
        {title:'硬件加速',desc:'网络加速和磁盘性能加速；支持多类型共享存储及分布式存储基础架构，实现低IOPS',imgSrc:require('@/assets/panshProduct/cloudDesktop/l2.png'),},
      ],
    }
  },
  methods: {
    openRightDrawer() {
        this.$refs.son.showRightDrawer();
    },
  },
}
</script>

<style scoped>
  .clearfix{clear: both;}
  .cloudDesktop{background: #F6F6F6;padding-top: 17%;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .cloa{padding-top: 15px;padding-bottom: 40px;}
  .cloa p{font-size: 3vw;text-indent: 2em;color: #888;margin: 5px 0;}
  .cloa img{width: 90%;display: block;margin: 0 auto;margin-top: 10px;}
  .clob{padding: 5px;margin-top: 10px; background: #fff;margin-bottom: 20px;}
  .clob > p{font-size: 3vw;color: #888;width: 94%;margin: 0 auto;text-indent: 2em;}
  .clob img{width: 96%;display: block;margin: 0 auto;}
  .cloba{width: 50%;float: left;}
  .cloba img{width: 30%;display: block;margin: 0 auto;}
  .cloba p:nth-child(2){font-size: 3.5vw;text-align: center;}
  .cloba p:nth-child(3){font-size: 3vw;color: #888;height: 90px;width: 94%;margin: 0 auto;}
  .cloc{margin-top: 20px;}
  .cloc > img{width: 100%;}
  .cloca{display: flex;}
  .clocb{width: 33.333%;}
  .clocb > div{}
  .clocba{width: 6px;height: 6px;border-radius: 3px;background: #888;float: left;margin-top: 6px;}
  .clocb p{font-size: 3vw;color: #888;list-style: disc;float: left;width: calc(100% - 15px);margin-left: 4px;}
</style>